/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { DockLayout, RCDockLayout } from '@digitalworkflow/dwreactcommon'

import style from './ManageProfile.module.scss'
import Roles from './Roles'
import Workgroups from './Workgroups'
import UserDetails from './UserDetails'
import ActivityLog from './ActiviyLog'
import ImportTools from './ImportTools'
import moment from 'moment'
import {
  AuthService,
  UserService,
  UserType
} from '@digitalworkflow/dwloginclient'
import { Spinner, Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import Portals from './Portals'
import { RcGroup } from '../RcGroup'
import Emails from './Emails'
import ChangePassword from '../ChangePassword/ChangePassword'
import { addFloatBox, getTab, removeRCTab } from '../../lib/rcDock-lib'
import CopyWorkgroupsFrom from '../CopyWorkgroupsFrom/CopyWorkgroupsFrom'
import FeatureAccess from './FeatureAccess'
import { checkSuperAdmin } from '../../utils/checkAdmin'
const authService = AuthService.instance()

UserService.setAuthServiceInstance(authService)
const userService = new UserService()
interface IManageProfile {
  id: string
  handleUpdateUser: (profile: UserType | undefined) => void
  myProfile?: boolean
}
interface FormValue {
  current_password: string
  new_password: string
  re_enter_password: string
}

const ManageProfile = ({ id, handleUpdateUser, myProfile }: IManageProfile) => {
  const [profile, setProfile] = useState<UserType | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isAllowedToModify, setAllowedToModify] = useState<boolean>(false)
  const dockLayoutRef = useRef<DockLayout | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await getUser() // Assuming getUser is also asynchronous
        const res = await userService.permissionToModify(id)
        if (res.is_error) {
          setAllowedToModify(false)
        } else {
          setAllowedToModify(true)
        }
      }
    }

    fetchData()
  }, [id, isAllowedToModify])

  useEffect(() => {
    if (dockLayoutRef && dockLayoutRef.current && profile) {
      dockLayoutRef.current.loadLayout(dockLayoutRef.current.getLayout())
    }
  }, [profile, dockLayoutRef])

  const handleCopyFrom = async (userId: string) => {
    if (dockLayoutRef) {
      if (dockLayoutRef.current?.find('copy_from')) {
        removeRCTab(dockLayoutRef, 'copy_from')
      }
      addFloatBox(
        dockLayoutRef,
        'copy_from',
        getTab(
          'copy_from',
          'Copy Workgroups From',
          dockLayoutRef,
          () => (
            <CopyWorkgroupsFrom
              userId={userId}
              cloneWorkgroupsFrom={cloneWorkgroupsFrom}
              closeCopyFrom={closeCopyFrom}
            />
          ),
          350,
          (document.getElementById('workgroups')?.clientWidth ?? 600) / 2 - 250,
          500,
          40
        )
      )
    }
  }
  const closeCopyFrom = () => {
    if (dockLayoutRef.current?.find('copy_from')) {
      removeRCTab(dockLayoutRef, 'copy_from')
    }
  }

  const cloneWorkgroupsFrom = async (fromUserId: string, toUserId: string) => {
    const _result = await userService.cloneWorkgroup(fromUserId, toUserId)
    if (_result && !_result.is_error) {
      toast.success('Workgroups have been cloned successfully.')
      removeRCTab(dockLayoutRef, 'copy_from')
      getUser() // Refresh User data when cloned
    } else {
      toast.error(_result?.message ?? 'From Submission Error')
    }
  }

  const handleChangePassword = () => {
    const isAdminChangePassword = isAllowedToModify
      ? { isManageProfile: true, profile }
      : { profile: null }

    addFloatBox(
      dockLayoutRef,
      'change_password',
      getTab(
        'change_password',
        'Change Password',
        dockLayoutRef,
        () => (
          <ChangePassword
            changeUserPassword={changeUserPassword}
            {...isAdminChangePassword}
          />
        ),
        350
      )
    )
  }

  const changeUserPassword = async (
    userId: string | null,
    values: FormValue
  ) => {
    let result
    if (userId) {
      result = await userService.ChangePasswordByAdmin(
        userId,
        values.new_password,
        values.re_enter_password
      )
    } else {
      result = await userService.ChangePasswordByUser(
        values.current_password,
        values.new_password,
        values.re_enter_password
      )
    }

    if (result && !result.is_error) {
      toast.success('Password has been updated successfully.')
      removeRCTab(dockLayoutRef, 'change_password')
    } else {
      toast.error(result?.message ?? 'From Submission Error')
    }
  }

  const getUser = useCallback(async () => {
    setLoading(true)
    const _result: ApiResultType<UserType> = await userService.GetUserById(id)
    setLoading(false)

    if (!_result.is_error) {
      setProfile(_result.data ?? null)
    }
  }, [profile, loading])

  const adminTabs = [
    {
      id: 'activityLog',
      title: 'Activity Log',
      content: <ActivityLog userInfo={profile} />,
      group: 'close-all'
    },
    {
      id: 'ImportTools',
      title: 'Import Tools',
      content: <ImportTools userInfo={profile} />,
      group: 'close-all'
    },
    {
      id: 'Email',
      title: 'Email Log',
      content: <Emails userInfo={profile} />,
      group: 'close-all'
    }
  ]

  const defaultLayout: any = {
    dockbox: {
      mode: 'horizontal',
      children: [
        {
          children: [
            {
              tabs: [
                {
                  id: 'portals',
                  title: 'Portals',
                  content: (
                    <Portals
                      profile={profile}
                      getUser={getUser}
                      isAllowedToModify={isAllowedToModify}
                    />
                  ),
                  group: 'close-all'
                },
                {
                  id: 'roles',
                  title: 'Roles',
                  content: (
                    <Roles
                      userInfo={profile}
                      getUser={getUser}
                      isAllowedToModify={isAllowedToModify}
                    />
                  ),
                  group: 'close-all'
                },
                {
                  id: 'workgroups',
                  title: 'Workgroups',
                  content: (
                    <Workgroups
                      userInfo={profile}
                      getUser={getUser}
                      isAllowedToModify={isAllowedToModify}
                      handleCopyFrom={handleCopyFrom}
                      myProfile={myProfile}
                    />
                  ),
                  group: 'close-all'
                },
                ...(checkSuperAdmin()
                  ? [
                      {
                        id: 'featureAccess',
                        title: 'Feature Access',
                        content: (
                          <FeatureAccess
                            userInfo={profile}
                            getUser={getUser}
                            userProfile={myProfile}
                          />
                        ),
                        group: 'close-all'
                      }
                    ]
                  : []),
                {
                  id: 'userDetails',
                  title: 'User Details',
                  content: (
                    <UserDetails
                      userId={id}
                      userInfo={profile}
                      getUser={getUser}
                      handleUpdateUser={handleUpdateUser}
                    />
                  ),
                  group: 'close-all'
                },
                ...(checkSuperAdmin() ? adminTabs : [])
              ],
              group: 'close-all'
            }
          ]
        }
      ]
    }
  }

  const handleActivateUser = useCallback(async () => {
    if (profile?.is_active) {
      const _result: ApiResultType<UserType> = await userService.deactivateUser(
        id
      )
      if (!_result.is_error) {
        toast.success('User has been successfully Deactivated')
      } else {
        toast.error('Failed to deactivate the user. Please try again.')
      }
    } else {
      const _result: ApiResultType<UserType> = await userService.activateUser(
        id
      )
      if (!_result.is_error) {
        toast.success('User has been successfully Activated')
      } else {
        toast.error('Failed to activate the user. Please try again.')
      }
    }
    getUser()
  }, [profile])

  return (
    <div className={`${style.container} ${style.bgStyle}`}>
      {loading && (
        <div className='d-flex justify-center'>
          <Spinner />
        </div>
      )}
      <div
        className={`d-flex justify-between flex-wrap gap-3 ${style.profileDetail}`}
      >
        <div className={`d-flex flex-wrap ${style.detail} gap-3`}>
          {/* <img src={placeholderImage} /> */}
          <div className='flex-1'>
            <h5>
              {profile?.first_name} {profile?.last_name}
            </h5>
            <div className='d-flex '>
              <div className='d-flex flex-wrap gap-3'>
                <div className='d-flex flex-col gap-1'>
                  <div className='d-flex gap-1 items-center'>
                    <i className={`fa-thin fa-envelope ${style.icon}`} />
                    <span>{profile?.email}</span>
                  </div>
                  <div className='d-flex  gap-1 items-center'>
                    <i className={`fa-thin fa-phone  ${style.icon}`} />
                    {profile?.phone && profile?.phone !== '' ? (
                      <span>{profile?.phone}</span>
                    ) : (
                      <span className={`${style.red}`}>Not yet provided</span>
                    )}
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='flex flex-col gap-1'>
                    <div className='flex gap-1 items-center'>
                      <i className={`fa-thin fa-location-dot ${style.icon}`} />
                      {profile?.location && profile?.location !== '' ? (
                        <span>{profile?.location}</span>
                      ) : (
                        <span className={`${style.red}`}>Not yet provided</span>
                      )}
                    </div>
                    <div className='d-flex gap-1 items-center'>
                      <i className={`fa-thin fa-sign-in  ${style.icon}`} />
                      {profile?.last_login_date ? (
                        <span>
                          Last login :{' '}
                          {moment(profile?.last_login_date).format('LLL')}
                        </span>
                      ) : (
                        <span className={`${style.red}`}>Never logged in</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!loading && (
          <div className='d-flex gap-1 '>
            <div>
              {!isAllowedToModify ? (
                (profile?.login_source === 'email' ||
                  profile?.login_source === 'REST API') && (
                  <Button
                    type='button'
                    color='cancel'
                    className='btn-sm'
                    onClick={handleChangePassword}
                  >
                    Change Password
                  </Button>
                )
              ) : profile?.is_active ? (
                <Button
                  type='button'
                  color='delete'
                  className='btn-sm'
                  onClick={handleActivateUser}
                >
                  <i className={`fa fa-times me-1 ${style.icon}`} />
                  Deactivate User
                </Button>
              ) : (
                <Button
                  type='button'
                  color='edit'
                  className='btn-sm'
                  onClick={handleActivateUser}
                >
                  <i className={`fal fa-user-plus me-1 ${style.icon}`} />
                  Activate User
                </Button>
              )}
            </div>

            {isAllowedToModify &&
              (profile?.login_source === 'email' ||
                profile?.login_source === 'REST API') && (
                <div className='ms-2'>
                  <Button
                    type='button'
                    color='primary'
                    className='btn-sm '
                    onClick={handleChangePassword}
                  >
                    Change Password
                  </Button>
                </div>
              )}
          </div>
        )}
      </div>
      <div className={style.layout}>
        {profile ? (
          <RCDockLayout
            defaultLayout={defaultLayout}
            dockLayoutRef={dockLayoutRef}
            groups={RcGroup}
            style={{ height: '100%' }}
          />
        ) : (
          <div className='d-flex justify-center'>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
export default ManageProfile

import { UserType, WorkgroupType } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from './LocalSettings'

const admins = [
  'DigitalWorkflow :: Admin',
  'DigitalWorkflow :: LoginPortal :: Admin',
  'DigitalWorkflow :: BuilderPortal :: Admin'
]

const adminIds = ['65b8e337d7ebb8674d99b790', '65b227f9fbdcb57aa22f6b10']

export const checkLoginPortalAdmin = (): boolean => {
  const userInfoString = LocalSettings.getUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some(
      (workgroup: WorkgroupType) =>
        admins[1] === workgroup.formatted_workgroup_name
    )
  } else {
    return false
  }
}

// Provide userInfo in parameter if we want to check if the user being edited in also a super admin
export const checkSuperAdmin = (userInfo?: UserType | null): boolean => {
  if (userInfo) {
    return userInfo.work_groups.some((workgroup: string) =>
      adminIds.includes(workgroup)
    )
  } else {
    const userInfoString = LocalSettings.getUser()
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString)
      return userInfo.work_groups.some((workgroup: WorkgroupType) =>
        admins.includes(workgroup.formatted_workgroup_name || '')
      )
    } else {
      return false
    }
  }
}

export const checkBGISAdmin = (): boolean => {
  const userInfoString = LocalSettings.getUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some((workgroup: WorkgroupType) => {
      const compareText1 = 'BGISAdmin'
      const compareTexts =
        workgroup.formatted_workgroup_name?.split(' :: ') ?? []
      const compareText2 =
        compareTexts[0] + compareTexts[compareTexts?.length - 1]
      return compareText1 === compareText2
    })
  } else {
    return false
  }
}

export const checkManageSystemAccess = (): boolean => {
  const userInfoString = LocalSettings.getUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some(
      (item: WorkgroupType) =>
        item.formatted_workgroup_name === 'BGIS :: Manage System Messages'
    )
  }
  return false
}

import React, { useEffect, useMemo } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import {
  WorldClock,
  Notepad,
  TreeView,
  AgGrid,
  FileAttachment
} from '@digitalworkflow/dwreactcommon'
import '@digitalworkflow/dwreactcommon/dist/index.css'
import './assets/scss/index.scss'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AuthService } from '@digitalworkflow/dwloginclient'

import { useAuth } from './context/AuthContext'

import logoImage from './assets/images/bgis-logo.png'
import logoSmImage from './assets/images/bgis-logo-sm.png'

import IdleWatch from './components/IdleWatch'
import DockPlaceholder from './components/DockPlaceholder'
import ManagePortals from './components/ManagePortals/ManagePortals'
import UserProfile from './pages/UserProfile/UserProfile'
import ManageRole from './pages/ManageRole/ManageRole'
import ManageUser from './pages/ManageUser/ManageUser'
import ManageWorkgroup from './pages/ManageWorkgroup/ManageWorkgroup'
import ManageClaimsMapping from './pages/ManageClaimsMapping/ManageClaimsMapping'
// import ManageEmailTemplates from './pages/ManageEmailTemplates/ManageEmailTemplates'
import EmailTemplateEditor from './components/EmailTemplatesList/EmailTemplateEditor'
import ManageAccessRequests from './pages/ManageAccessRequests/ManageAccessRequests'
import Login from './pages/Login/Login'
import ForceLogout from './pages/ForceLogout/ForceLogout'
import SSORedirect from './pages/Login/SSORedirect'
import ClearStorage from './pages/ClearStorage'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import Dashboard from './pages/Dashboard'

import Buttons from './examples/Buttons'
import DescriptiveButtons from './examples/DescriptiveButtons'
import RcDockLayoutPage from './examples/RcDockLayoutPage'
import MillerColumns from './examples/MillerColumns'
import BuildingSearch from './examples/BuildingSearch'
import ToastMessages from './examples/ToastMessages'
import SelectDropdownPage from './examples/SelectDropdownPage'
import TaskList from './examples/TaskList'
import FormSelectionPage from './examples/FormSelection'
import DWAutocompletePage from './examples/DWAutocompletePage'
import SearchDropdownPage from './examples/SearchDropdown'
import LookupManagerPage from './examples/LookupManagerPage'
import SingleFileAttachmentPage from './examples/SingleFileAttachmentPage'
import packageInfo from '../package.json'

import {
  profileMenuItems,
  colDef,
  gridData,
  portalName,
  apiBaseURL
} from './constants'
import { getEnv } from './utils/getEnv'
import { LocalSettings } from './utils/LocalSettings'
import SystemMessages from './pages/SystemMessages/SystemMessages'
import AdminPortalLayoutWrapper from './components/AdminPortalLayoutWrapper'
import CronJobHistory from './pages/CronJobHistory/CronJobHistory'
import { getFeatureAccessBasedNavbar } from './utils/getfeatureAccessNavbar'
import FeatureAccessProtectedRoute from './components/FeatureAccessProtectedRoute'
import SentEmailReports from './pages/EmailReports/SentEmailReports'
import BlockedEmailReports from './pages/EmailReports/BlockedEmailReports'
import FailedEmailReports from './pages/EmailReports/FailedEmailReports'

AuthService.setEnvironment(getEnv() as any)

const GuestRouteRedirect = () => {
  // const navigate = useNavigate()

  // Capture the internal URL
  const internalUrl = window.location.pathname

  // Store the internal URL in local storage
  LocalSettings.setRedirectUrl(internalUrl)

  // navigate(`/login/${portalName}`)
  window.location.href = `/login/${portalName}`

  return null
}

const authService = AuthService.instance()
const App = () => {
  const {
    isAuthenticated,
    user,
    featureAccessSidebarUpdated,
    setFeatureAccessSidebarUpdated
  } = useAuth()
  const portalString = LocalSettings.getLoginPortalData()
  const portalData = portalString ? JSON.parse(portalString) : null

  const sidebarMenu = useMemo(() => {
    let _menuItems = [...getFeatureAccessBasedNavbar()]

    if (getEnv() === 'prod') {
      _menuItems = _menuItems.filter((item) => item.title_en !== 'Components')
    }
    return _menuItems
  }, [LocalSettings.getUser(), featureAccessSidebarUpdated])

  useEffect(() => {
    const getUserDetail = async () => {
      const result = await authService.authUserFromToken(
        LocalSettings.getAuthToken() ?? ''
      )
      if (result.data.user) {
        LocalSettings.setUser(JSON.stringify(result.data.user))
        setFeatureAccessSidebarUpdated((prev) => ++prev)
      }
    }
    if (LocalSettings.getAuthToken()) {
      getUserDetail()
    }
  }, [LocalSettings.getAuthToken()])

  return (
    <>
      <BrowserRouter>
        <IdleWatch />
        <DockPlaceholder />
        <Routes>
          <Route
            path='/'
            element={
              isAuthenticated ? (
                <Navigate
                  to={{
                    pathname: '/hub/users' ?? '/hub/dashboard'
                  }}
                />
              ) : (
                <Navigate to={{ pathname: `/login/${portalName}` }} />
              )
            }
          />
          <Route path='/login/:id' element={<Login />}></Route>
          <Route path='/logout/:id' element={<ForceLogout />} />
          <Route path='/login/:id/sso/redirect' element={<SSORedirect />} />
          <Route path='/clear' element={<ClearStorage />} />
          <Route
            path='/forgot-password/:id'
            element={
              !isAuthenticated ? (
                React.createElement(ForgotPassword, {})
              ) : (
                <Navigate to={{ pathname: '/hub/dashboard' }} />
              )
            }
          ></Route>
          <Route
            path='/hub/email-templates-editor/:id'
            element={<EmailTemplateEditor />}
          ></Route>
          <Route path='/hub/dashboard' element={<Dashboard />} />
          <Route
            path='/hub'
            element={
              isAuthenticated ? (
                <AdminPortalLayoutWrapper
                  menuItems={sidebarMenu}
                  profileMenuItems={profileMenuItems}
                  logoImage={
                    portalData !== null && portalData.portal_logo_full !== ''
                      ? `${apiBaseURL + portalData.portal_logo_full}`
                      : logoImage
                  }
                  logoSmImage={logoSmImage}
                  username={user?.email}
                  showWorldClock={false}
                  versionNumber={packageInfo.version}
                  env={getEnv()}
                />
              ) : (
                React.createElement(GuestRouteRedirect, {})
              )
            }
          >
            <Route
              path='roles'
              element={
                <FeatureAccessProtectedRoute>
                  <ManageRole />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route
              path='portals'
              element={
                <FeatureAccessProtectedRoute>
                  <ManagePortals />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route path='profile' element={<UserProfile />} />
            <Route
              path='users'
              element={
                <FeatureAccessProtectedRoute>
                  <ManageUser />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route
              path='workgroups'
              element={
                <FeatureAccessProtectedRoute>
                  <ManageWorkgroup />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route
              path='claims-mapping'
              element={
                <FeatureAccessProtectedRoute>
                  <ManageClaimsMapping />
                </FeatureAccessProtectedRoute>
              }
            />
            {/* <Route
              path='email-templates'
              element={
                <FeatureAccessProtectedRoute>
                  <ManageEmailTemplates />
                </FeatureAccessProtectedRoute>
              }
            /> */}
            <Route
              path='system-messages'
              element={
                <FeatureAccessProtectedRoute>
                  <SystemMessages />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route
              path='cronjob-history'
              element={
                <FeatureAccessProtectedRoute>
                  <CronJobHistory />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route
              path='email-reports/sent-emails'
              element={
                <FeatureAccessProtectedRoute>
                  <SentEmailReports />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route
              path='email-reports/blocked-emails'
              element={
                <FeatureAccessProtectedRoute>
                  <BlockedEmailReports />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route
              path='email-reports/failed-emails'
              element={
                <FeatureAccessProtectedRoute>
                  <FailedEmailReports />
                </FeatureAccessProtectedRoute>
              }
            />
            <Route path='access' element={<ManageAccessRequests />} />
            {/* common components */}
            <Route path='tree-view' element={<TreeView isShowDropdown />} />
            <Route path='clock' element={<WorldClock />} />
            <Route
              path='notepad'
              element={
                <Notepad
                  id='1234'
                  title='Task Notepad'
                  projectId=''
                  objectType=''
                  relatedObject=''
                  createdBy=''
                  updatedBy=''
                />
              }
            />
            <Route
              path='builder-grid'
              element={<div style={{ height: '400px' }}></div>}
            />
            <Route
              path='ag-grid'
              element={
                <div style={{ height: '400px' }}>
                  <AgGrid columnDefs={colDef} rowData={gridData} />
                </div>
              }
            />
            <Route
              path='ag-grid-balham'
              element={
                <div style={{ height: '400px' }}>
                  <AgGrid
                    columnDefs={colDef}
                    rowData={gridData}
                    theme='balham'
                  />
                </div>
              }
            />
            <Route path='rc-dock' element={<RcDockLayoutPage />} />
            <Route path='descriptive-button' element={<DescriptiveButtons />} />
            <Route path='task-list' element={<TaskList />} />
            <Route path='miller-columns' element={<MillerColumns />} />
            <Route path='buttons' element={<Buttons />} />
            <Route path='toast-messages' element={<ToastMessages />} />
            <Route path='building-search' element={<BuildingSearch />} />
            <Route
              path='file-attachment'
              element={<FileAttachment ref_id='' />}
            />
            <Route path='form-selection' element={<FormSelectionPage />} />
            <Route path='dw-autocomplete' element={<DWAutocompletePage />} />
            <Route path='search-dropdown' element={<SearchDropdownPage />} />
            <Route path='select-dropdown' element={<SelectDropdownPage />} />
            <Route path='lookup-manager' element={<LookupManagerPage />} />
            <Route
              path='single-file-attachment'
              element={<SingleFileAttachmentPage />}
            />
          </Route>
        </Routes>
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </BrowserRouter>
    </>
  )
}

export default App

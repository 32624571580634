import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import moment from 'moment'
import {
  AuthService,
  SystemMessageService,
  SystemMessageType
} from '@digitalworkflow/dwloginclient'
import { SortDirection } from 'ag-grid-community'
import ToggleButton from '../ToggleButton/ToggleButton'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap'
import { toast } from 'react-toastify'
import AgGridTooltip from '../AgGrdiTooltip/AgGridTooltip'

const defaulColDef = {
  sortable: true,
  unSortIcon: true,
  filter: 'agTextColumnFilter',
  minWidth: 140,
  floatingFilter: true
}
interface Props {
  handleEditMessage: (value: SystemMessageType) => void
}
const SystemMessageList = forwardRef(({ handleEditMessage }: Props, ref) => {
  const authService = AuthService.instance()
  SystemMessageService.setAuthServiceInstance(authService)
  const systemMessageService = new SystemMessageService()
  const [messagesList, setMessagesList] = useState<SystemMessageType[] | null>(
    []
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [modal, setModal] = useState(false)
  const [selectedMessage, setSelectedMessage] =
    useState<SystemMessageType | null>(null)

  const toggleModal = (message: SystemMessageType | null) => {
    setSelectedMessage(message)
    setModal(!modal)
  }

  const handleStatusChange = async () => {
    const _result: ApiResultType<SystemMessageType> =
      await systemMessageService.updateStatusById(selectedMessage?.id ?? '')
    if (!_result.is_error) {
      toast.success('Record updated successfully!')
      getAllMessages()
    } else {
      toast.error(_result.message)
    }
    toggleModal(null)
  }

  useEffect(() => {
    getAllMessages()
  }, [])
  useImperativeHandle(ref, () => ({
    getAllMessages
  }))

  const getAllMessages = useCallback(async () => {
    setLoading(true)
    const _result: ApiResultType<SystemMessageType[]> =
      await systemMessageService.getAll()
    setLoading(false)
    if (!_result.is_error) {
      setMessagesList(_result.data ?? null)
    }
  }, [setMessagesList, setLoading])
  const columnDefs = [
    {
      headerName: 'Message Title',
      field: 'message_title',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      valueFormatter: (params: any) => {
        return params.data.message_title.en
          ? params.data.message_title.en
          : params.data.message_title.fr
      },
      valueGetter: (params: any) => {
        return params.data.message_title.en
          ? params.data.message_title.en
          : params.data.message_title.fr
      },
      cellRenderer: (params: any) => {
        const messageTitle = params.value ?? ''
        return <AgGridTooltip text={messageTitle} placement='top' />
      },
      flex: 2,
      resizable: true
    },
    {
      headerName: 'Message Language',
      field: 'language',
      valueFormatter: (params: any) => {
        return params.data.message_title.en ? 'English' : 'French'
      },
      valueGetter: (params: any) => {
        return params.data.message_title.en ? 'English' : 'French'
      },
      flex: 2,
      resizable: true
    },
    {
      headerName: 'Message Date',
      field: 'message_date',
      flex: 2,
      resizable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      sort: 'desc' as SortDirection,
      valueFormatter: (params: any) => {
        return params.data.message_date
          ? moment(params.data.message_date).format('LLL')
          : ''
      },
      cellRenderer: (params: any) => {
        return moment(params.data.message_date).format('LLL')
      },
      filterParams: {
        maxValidDate: new Date(),
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (!cellValue) {
            return -1
          }
          const date = new Date(cellValue)
          const year = Number(date.getFullYear())
          const month = Number(date.getMonth())
          const day = Number(date.getDate())
          const cellDate = new Date(year, month, day)

          if (cellDate < filterLocalDateAtMidnight) {
            return -1
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1
          }

          return 0
        }
      }
    },
    {
      headerName: 'Status',
      field: 'is_enabled',
      filter: '',
      sortable: false,
      flex: 1,
      resizable: true,
      cellRenderer: (_params: any) => (
        <ToggleButton
          name='status'
          isChecked={_params.data.is_enabled}
          onChange={() => toggleModal(_params.data)}
        />
      )
    },
    {
      headerName: 'Action',
      field: '',
      filter: '',
      sortable: false,
      flex: 1,
      resizable: true,
      cellRenderer: (_params: any) => (
        <span
          className='action-text'
          onClick={() => handleEditMessage(_params.data)}
        >
          Manage Message
        </span>
      )
    }
  ]
  return (
    <div style={{ height: '100%' }}>
      {loading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <LoginPortalGrid
          rowData={messagesList}
          defaulColDef={defaulColDef}
          columnDefs={columnDefs}
        />
      )}
      <div>
        <Modal isOpen={modal} toggle={() => toggleModal(null)}>
          <ModalHeader toggle={() => toggleModal(null)}>
            Update Message Status
          </ModalHeader>
          <ModalBody>
            Are you sure you want to change status of message!
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={handleStatusChange}>
              Yes
            </Button>{' '}
            <Button color='secondary' onClick={() => toggleModal(null)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
})
export default SystemMessageList

import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'

import ToggleButton from '../ToggleButton/ToggleButton'
import { Button, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import style from './ManageProfile.module.scss'
import '../../style/roleContianer.scss'
import {
  AuthService,
  RoleService,
  UserService,
  RoleType,
  UserType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { sortComparator } from '../../utils/sortArray'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { compareArrays } from '../../utils/compareArray'
interface Props {
  userInfo: UserType | null
  getUser: () => void
  isAllowedToModify?: boolean
}
type SortDirection = 'asc' | 'desc' | null

const Roles = ({ userInfo, getUser, isAllowedToModify = true }: Props) => {
  const rolesRef = useRef<RoleType[]>([])
  const [userRoles, setUserRoles] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [bulkActionToggle, setBulkActionToggle] = useState<boolean>(false)

  const authService = AuthService.instance()
  RoleService.setAuthServiceInstance(authService)
  UserService.setAuthServiceInstance(authService)
  const roleService = new RoleService()
  const userService = new UserService()

  const isDisabled = useMemo(() => {
    return compareArrays((userInfo?.roles as string[]) ?? [], userRoles)
  }, [userInfo, userRoles])

  const getAllRoles = useCallback(async () => {
    try {
      setIsLoading(true)
      const rolesRes = await roleService.getAllRole('restricted')
      if (!rolesRes.is_error) {
        const data = rolesRes.data ?? []
        if (data.length > 0) {
          const sortData = [...data].sort(sortComparator('formatted_role_name'))
          rolesRef.current = sortData
        } else {
          rolesRef.current = []
        }

        if (
          userInfo?.roles &&
          userInfo?.roles.length > 0 &&
          userInfo?.roles.length === rolesRef.current.length
        ) {
          handleBulkActionToggle(true)
        }
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [setIsLoading])

  useEffect(() => {
    getAllRoles()
    setUserRoles(userInfo?.roles ?? [])
  }, [])

  const handleBulkActionToggle = (value: boolean) => {
    setBulkActionToggle(value)
    const _allRolesIds = rolesRef.current.map(
      (data: RoleType) => data.id
    ) as string[]
    if (value) {
      setUserRoles(_allRolesIds)
    } else {
      setUserRoles([])
    }
  }

  const columnDefs = useMemo(() => {
    return [
      {
        field: 'formatted_role_name',
        headerName: 'Role Name',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        minWidth: 200,
        flex: 2,
        floatingFilter: true,
        comparator: sortComparator(null)
      },
      {
        field: 'work_groups',
        headerName: 'Workgroups',
        unSortIcon: true,
        resizable: true,
        width: 100,
        flex: 1,
        cellRenderer: (params: any) => (
          <div>{params.data.work_groups?.length}</div>
        )
      },
      ...(isAllowedToModify
        ? [
            {
              field: 'action',
              headerName: 'Action',
              width: 100,
              flex: 1,
              resizable: true,
              sort: 'desc' as SortDirection,
              filter: 'agNumberColumnFilter',
              floatingFilter: true,
              floatingFilterComponent: ToggleButton,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                isChecked: bulkActionToggle,
                onChange: (e: any) => handleBulkActionToggle(e.target.checked)
              },
              valueGetter: (params: any) => userRoles.includes(params.data.id),
              cellRenderer: (params: any) => (
                <div
                  className={`flex justify-between items-center ${style.actionContainer}`}
                >
                  <ToggleButton
                    name='role'
                    isChecked={userRoles.includes(params.data.id)}
                    onChange={(event: any) =>
                      handleToggleActive(params.data.id, event.target.checked)
                    }
                  />
                </div>
              )
            }
          ]
        : [])
    ]
  }, [userRoles])

  useEffect(() => {
    console.log(userRoles)
    setBulkActionToggle(false)
  }, [userRoles])

  const handleToggleActive = useCallback(
    (id: string, isActive: boolean) => {
      if (isActive) {
        setUserRoles((userRoles) => [...userRoles, id])
      } else {
        setUserRoles((userRoles) => userRoles.filter((r) => r !== id))
      }

      // setRoles((roles) => {
      //   return roles.map((role) => {
      //     if (role.id === id) {
      //       return {
      //         ...role,
      //         is_enabled: isActive
      //       }
      //     }
      //     return role
      //   })
      // })
    },
    [userRoles]
  )

  const handleUpdate = useCallback(async () => {
    if (userInfo?.id) {
      setIsSubmitting(true)
      try {
        const _result: ApiResultType<UserType> =
          await userService.updateUserById(userInfo?.id, {
            roles: userRoles
          })
        if (!_result.is_error) {
          toast.success('Roles have been successfully updated')
        } else {
          toast.error(_result.message ?? 'Roles Update Error')
          handleCancel()
        }
      } catch (e) {
        console.log(e)
        toast.error('Roles Update Error')
      }
      getUser()
      setIsSubmitting(false)
    }
  }, [userRoles])

  const handleCancel = () => {
    setUserRoles(userInfo?.roles ?? [])
  }

  const rolesData = useMemo(() => {
    return isAllowedToModify
      ? rolesRef.current
      : rolesRef.current.filter((item: any) => userRoles.includes(item.id))
  }, [isAllowedToModify, userRoles, rolesRef.current])

  return (
    <div className={style.portalContainer}>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className={style.gridPortalContainer}>
          {isAllowedToModify && (
            <div className='mb-2 mt-2 d-flex justify-content-end'>
              <div className='d-flex gap-2'>
                <Button
                  type='submit'
                  color='edit'
                  className='btn-sm'
                  onClick={handleUpdate}
                  disabled={isDisabled}
                >
                  <i className='fal fa-edit me-2' />
                  Update
                  {isSubmitting && <Spinner className={style.spinner} />}
                </Button>
                <Button
                  type='button'
                  color='cancel'
                  className='btn-sm'
                  disabled={isDisabled}
                  onClick={handleCancel}
                >
                  <i className='fa fa-times me-2' />
                  Cancel
                </Button>
              </div>
            </div>
          )}
          <LoginPortalGrid columnDefs={columnDefs} rowData={rolesData} />
        </div>
      )}
    </div>
  )
}
export default Roles

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import { toast } from 'react-toastify'
import RoleList from '../../container/RoleList/RoleList'
import WorkgroupRoleDetails from '../../container/WorkgroupRole/WorkgroupRoleDetails'
import AddRole from '../../container/AddRole/AddRole'
import '../../style/adminSetting.scss'
import '../../style/roleContianer.scss'
import { RcGroup } from '../../components/RcGroup'
import {
  AuthService,
  RoleService,
  RoleType,
  WorkgroupType
} from '@digitalworkflow/dwloginclient'
import EditRole from '../../container/EditRoleWokgroup/EditRole'
import AssignNewWorkgroupRole from '../../container/WorkgroupRole/AssignNewWorkgroupRole'
import { ColDef } from 'ag-grid-community'
import { useIsOverflow } from '../../hooks/userIsOverflow'
import { Tooltip } from 'reactstrap'
import LoginPortalGrid from '../../components/LoginPortalGrid/LoginPortalGrid'
import TabClose from '../../components/TabClose'
import { sortArrayByEveryPart } from '../../utils/sortArray'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { getSize } from '../../utils/getSize'
const roleService = new RoleService()

RoleService.setAuthServiceInstance(AuthService.instance())
const defaulColDef = {
  sortable: true,
  unSortIcon: true,
  filter: 'agTextColumnFilter',
  minWidth: 140,
  floatingFilter: true
}

const ManageRole = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const roleListRef = useRef<any>(null)
  const gridRef = useRef(null)
  const [activeRole, setActiveRole] = useState<RoleType | null>(null)
  const [toggle, setToggle] = useState<boolean>(false)
  const [workgroupMembers, setWorkgroupMembers] = useState<
    WorkgroupType[] | null
  >(null)
  const [isAllowedToModify, setAllowedToModify] = useState<boolean>(false)
  const [key, setKey] = useState(0)

  useEffect(() => {
    if (dockLayoutRef && dockLayoutRef.current && activeRole) {
      const _layouts = dockLayoutRef.current.getLayout()
      dockLayoutRef.current.loadLayout(_layouts)
      const addWorkgroup = _layouts.floatbox?.children.find(
        (item: any) => item.activeId === 'addRole'
      )
      if (addWorkgroup) {
        handleAddFloatBox()
      } else {
        const editWorkgroup = _layouts.floatbox?.children.find(
          (item: any) => item.activeId === 'editRole'
        )
        if (editWorkgroup) {
          handleEditFloatBox()
        }
      }
    }
  }, [activeRole, toggle])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        field: 'formatted_workgroup_name',
        headerName: 'Workgroup Name',
        sort: 'asc',
        minWidth: 180,
        flex: 3,
        resizable: true,
        comparator: sortArrayByEveryPart(null),
        cellRenderer: (params: any) => (
          <FormatedWorkgroup workgroup={params.data} />
        )
      },
      {
        field: 'total_members',
        headerName: 'Total Members',
        maxWidth: 170,
        flex: 1,
        resizable: true,
        headerClass: 'ag-left-aligned-header'
        // cellClass: 'ag-right-aligned-cell'
      },
      ...(isAllowedToModify
        ? [
            {
              headerName: 'Action',
              field: 'action',
              minWidth: 140,
              flex: 2,
              resizable: true,
              sortable: false,
              floatingFilter: false,
              cellRenderer: (params: any) => (
                <div
                  className='action-text'
                  onClick={() => handleRemoveWorkgroup(params.data.id)}
                >
                  Remove this workgroup from role
                </div>
              )
            }
          ]
        : [])
    ]
  }, [activeRole, workgroupMembers])
  const handleAddRole = () => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const layouts = dockLayoutRef.current.getLayout()
      const _workgroupListTab: any = layouts.maxbox?.children.find(
        (item: any) => item.activeId === 'role_list'
      )
      if (_workgroupListTab && layouts.maxbox) {
        layouts.maxbox.children = layouts.maxbox?.children.filter(
          (item: any) => item.activeId !== 'role_list'
        )
        layouts.dockbox.children = layouts.dockbox?.children.map(
          (item: any) => {
            item = item.activeId === 'role_list' ? roleList : item
            if (item?.children) {
              item.children = item.children.map((i: any) => {
                const _child = i.activeId === 'role_list' ? roleList : i
                return _child
              })
            }
            return item
          }
        )

        dockLayoutRef.current.loadLayout(layouts)
      }
    }
    handleAddFloatBox()
  }

  const handleRemoveWorkgroup = useCallback(
    async (id: string) => {
      if (workgroupMembers) {
        const _workgroupMembersList: string[] = workgroupMembers
          .filter((workgroup) => workgroup.id !== id)
          .map((workgroup) => workgroup.id as string)
        const value = {
          role_name: activeRole?.role_name ?? '',
          parent_role_id: activeRole?.parent_role_id ?? '',
          approver_group_name_id: activeRole?.approver_group_name_id ?? '',
          description: activeRole?.description ?? '',
          is_enabled: activeRole?.is_enabled,
          work_groups: _workgroupMembersList
        }
        const result: ApiResultType<RoleType> =
          await roleService.updateRoleById(activeRole?.id ?? '', value)
        if (!result.is_error) {
          let _isRemoved = false
          const _workgroups = result.data?.work_groups

          if (_workgroups !== undefined) {
            for (const workgroup of _workgroups) {
              if (typeof workgroup === 'object' && workgroup.id !== undefined) {
                _isRemoved = _workgroupMembersList.includes(workgroup.id)
                if (!_isRemoved) break
              }
            }
          } else {
            _isRemoved = true
          }

          if (!_isRemoved) {
            toast.error(
              'Workgroup cannot be removed from this role as it is inherited from the parent role'
            )
          } else {
            toast.success('Workgroup is removed successfully from this role')
            handleNewRole()
          }
        }
      }
    },
    [workgroupMembers]
  )
  const handleEditRole = () => {
    // if (dockLayoutRef && dockLayoutRef.current) {
    //   const dockInstance = dockLayoutRef.current

    //   const layouts = dockInstance.getLayout()
    //   const _workgroupListTab: any = layouts.maxbox?.children.find(
    //     (item: any) => item.id === 'role_detail_container'
    //   )
    //   if (_workgroupListTab && layouts.maxbox) {
    //     const _layout: any = dockInstance.find('role_detail_container')
    //     if (_layout) {
    //       dockInstance.dockMove(_layout as any, null, 'remove')
    //     }
    //     dockInstance.dockMove({ ...roleDetails }, 'roleSecondTab', 'before-tab')
    //     const _tab: any = dockInstance.find('role_detail_container')
    //     if (_tab) {
    //       _tab.size = 50
    //       dockInstance.loadLayout(dockInstance.saveLayout())
    //     }
    //   }
    // }
    handleEditFloatBox()
  }
  const handleNewRole = async () => {
    if (roleListRef && roleListRef.current) {
      await roleListRef.current.getRoles()
    }
  }
  const handleEditFloatBox = () => {
    if (dockLayoutRef && dockLayoutRef.current) {
      if (!dockLayoutRef.current.find('editRole')) {
        dockLayoutRef.current.dockMove(
          {
            id: 'editRole',
            tabs: [
              {
                id: 'editRole',
                title: (
                  <>
                    <span>Edit Role</span>
                    <TabClose dockLayoutRef={dockLayoutRef} id='editRole' />
                  </>
                ),
                content: (
                  <EditRole
                    activeRole={activeRole}
                    handleNewRole={handleNewRole}
                    dockLayoutRef={dockLayoutRef.current}
                    id='editRole'
                  />
                ),
                group: 'close-all'
              }
            ],
            group: 'close-all',
            x: 200,
            y: 10,
            w: 500,
            h: 440,
            z: 9999
          },
          null,
          'float'
        )
      }
    }
  }
  const handleAddFloatBox = () => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const _maximized: any = dockLayoutRef.current.find(
        '-maximized-placeholder-'
      )
      if (_maximized) {
        dockLayoutRef.current.dockMove(_maximized as any, null, 'remove')
      }
      if (!dockLayoutRef.current.find('addRole')) {
        dockLayoutRef.current.dockMove(
          {
            id: 'addRole',
            tabs: [
              {
                id: 'addRole',
                title: (
                  <>
                    <span>Add Role</span>
                    <TabClose dockLayoutRef={dockLayoutRef} id='addRole' />
                  </>
                ),
                content: (
                  <AddRole
                    handleRemoveRole={handleRemoveRole}
                    handleNewRole={handleNewRole}
                  />
                ),
                group: 'close-all'
              }
            ],
            group: 'close-all',
            x: 50,
            y: 10,
            w: 900,
            h: 780,
            z: 9999
          },
          null,
          'float'
        )
      }
    }
  }

  const handleRemoveRole = () => {
    const currentLayout = dockLayoutRef.current?.getLayout()
    if (currentLayout && currentLayout.floatbox) {
      const _index = currentLayout.floatbox.children.findIndex(
        (item) => item.id === 'addRole'
      )
      currentLayout.floatbox.children.splice(_index, 1)
    }
    if (dockLayoutRef && dockLayoutRef.current) {
      dockLayoutRef.current.setLayout(currentLayout as any)
      dockLayoutRef.current.loadLayout(currentLayout as any)
    }
  }

  const getWorkgroupRole = async (id: string) => {
    const res = await roleService.GetRoleById(id)
    if (res && res.data) {
      setWorkgroupMembers([...(res?.data?.work_groups as WorkgroupType[])])
    } else {
      setWorkgroupMembers([])
    }
  }

  const handleActiveRole = useCallback(
    async (role: RoleType) => {
      if (role.id) {
        await getWorkgroupRole(role.id)
        const res = await roleService.permissionToModify(role.id)
        if (res.is_error) {
          setAllowedToModify(false)
        } else {
          setAllowedToModify(true)
        }
        setActiveRole(role)
      }
    },
    [setActiveRole, activeRole, isAllowedToModify]
  )

  const roleList = {
    size: 25,
    children: [
      {
        tabs: [
          {
            id: 'role_list',
            title: 'Role list',
            content: (
              <RoleList
                handleAddRole={handleAddRole}
                ref={roleListRef}
                activeRole={activeRole}
                handleActiveRole={handleActiveRole}
              />
            ),
            group: 'close-all'
          }
        ]
      }
    ]
  }
  const roleDetails = {
    size: 10,
    id: 'role_detail_container',
    panelLock: true,
    tabs: [
      {
        id: 'role_detail',
        title: 'Role Detail',
        content: activeRole ? (
          <WorkgroupRoleDetails
            isAllowedToModify={isAllowedToModify}
            handleEditWorkgroupRole={handleEditRole}
            activeWorkgroupRole={activeRole}
            editButtonText='Edit Role'
            addUsersButtonText='Add Users'
            isWorkgroup={false}
            dockLayoutRef={dockLayoutRef}
          />
        ) : (
          <span className='defaultBlankText'>
            Details are based on selected role from the list
          </span>
        ),
        minWidth: 520,
        minHeight: 118,
        group: 'close-all'
      },
      ...(isAllowedToModify
        ? [
            {
              id: 'assgn_new_workgroup_role',
              title: 'Manage Workgroups of this role',
              content: (
                <AssignNewWorkgroupRole
                  activeRole={activeRole}
                  dockLayoutRef={dockLayoutRef}
                  toggle={toggle}
                  setToggle={setToggle}
                  handleNewRole={handleNewRole}
                />
              ),
              panelStyle: 'bg-transparent',
              minHeight: 118,
              group: 'close-all'
            }
          ]
        : [])
    ]
  }

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          size: getSize(1800, 1080),
          mode: 'vertical',
          children: [
            {
              tabs: [
                {
                  id: 'description',
                  title: 'MANAGE ROLES',
                  content: (
                    <div className='p-2'>
                      <div className='mt-1'>
                        <h6>MANAGE ROLES</h6>
                      </div>
                      <p className='descripton'>
                        Role defines what users are allowed to see. The
                        hierarchy come in to place here. The person belong to
                        parent role can see everything of children role but the
                        person with child role can't see what Parent role can
                        see!
                      </p>
                    </div>
                  ),
                  group: 'headless',
                  size: 'auto'
                }
              ]
            }
          ]
        },

        {
          mode: 'horizontal',
          children: [
            roleList,
            {
              mode: 'vertical',
              size: 75,
              children: [
                roleDetails,
                {
                  size: 75,
                  id: 'roleSecondTab',
                  tabs: [
                    {
                      id: 't3',
                      title: 'Workgroups within this role',
                      content: (
                        <LoginPortalGrid
                          rowData={workgroupMembers}
                          columnDefs={columnDefs}
                          defaulColDef={defaulColDef}
                          gridRef={gridRef}
                        />
                      ),
                      group: 'close-all'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [isAllowedToModify])

  return (
    <RCDockLayout
      key={key}
      defaultLayout={defaultLayout}
      dockLayoutRef={dockLayoutRef}
      groups={RcGroup}
    />
  )
}
export default ManageRole

const FormatedWorkgroup = ({ workgroup }: { workgroup: WorkgroupType }) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const parentRef = useRef(null)
  const childRef = useRef(null)

  const isOverflow = useIsOverflow(parentRef, childRef)

  return (
    <div
      ref={parentRef}
      className={`text-truncate ${isOverflow ? 'cursor-pointer' : ''}`}
    >
      <span ref={childRef} id={'titleTooltip' + workgroup.id}>
        {workgroup.formatted_workgroup_name}
      </span>
      {isOverflow && (
        <Tooltip
          isOpen={tooltipOpen}
          target={'titleTooltip' + workgroup.id}
          toggle={toggle}
        >
          {workgroup.formatted_workgroup_name}
        </Tooltip>
      )}
    </div>
  )
}

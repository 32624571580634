import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { Button, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import {
  ColumnProps,
  DockLayout,
  MillerColumns,
  SelectedColumnProps,
  TabData
} from '@digitalworkflow/dwreactcommon'
import {
  AuthService,
  BulkService,
  RoleType,
  WorkgroupService,
  WorkgroupType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import '../../style/roleContianer.scss'

const authService = AuthService.instance()

const workgroupService = new WorkgroupService()
const bulkService = new BulkService()

WorkgroupService.setAuthServiceInstance(authService)
BulkService.setAuthServiceInstance(authService)

interface INewWorkgroupRole {
  activeRole: RoleType | null
  toggle: boolean
  setToggle: (value: boolean) => void
  handleNewRole: () => void
  dockLayoutRef: RefObject<DockLayout | null>
}

const AssignNewWorkgroupRole = ({
  activeRole,
  handleNewRole,
  toggle,
  setToggle,
  dockLayoutRef
}: INewWorkgroupRole) => {
  const [loading, setIsLoading] = useState<boolean>(false)
  const [workgroups, setWorkgroups] = useState<ColumnProps[]>([])
  const [totalWorkgroups, setTotalWorkgroups] = useState<ColumnProps[]>([])
  const [selectedWorkgroups, setSelectedWorkgroups] = useState<
    SelectedColumnProps[]
  >([])
  const [isShow, setIsShow] = useState<boolean>(false)
  useEffect(() => {
    getWorkgroups()
  }, [activeRole])

  useEffect(() => {
    updateTabSize()
  }, [toggle])

  const updateTabSize = useCallback(() => {
    const dockInstance = dockLayoutRef.current
    if (dockInstance) {
      const tab = dockLayoutRef.current?.find(
        'assgn_new_workgroup_role'
      ) as TabData
      const updatedTab = { ...tab, minHeight: toggle ? 415 : 118 }
      dockLayoutRef.current?.updateTab('assgn_new_workgroup_role', updatedTab)
    }
  }, [toggle])

  const getWorkgroups = async () => {
    setIsLoading(true)
    const _workgroups: ApiResultType<WorkgroupType[]> =
      await workgroupService.getAllWorkgroup()
    setIsLoading(false)

    if (!_workgroups.is_error && _workgroups.data) {
      const _workgroupList = transformData(_workgroups.data)

      const _data = JSON.stringify(_workgroupList)
      const result = JSON.parse(_data)
      setWorkgroups([..._workgroupList])
      setTotalWorkgroups(result)
    }
  }

  const transformData = useCallback(
    (inputData: WorkgroupType[]) => {
      const nodeMap: { [id: string]: any } = {}

      const rootNodes: any[] = []

      inputData.sort((a: WorkgroupType, b: WorkgroupType) =>
        a.work_group_name.trim().localeCompare(b.work_group_name.trim(), 'en', {
          sensitivity: 'base'
        })
      )

      inputData.forEach((item: WorkgroupType) => {
        const node: any = {
          id: item.id ?? '',
          title: item.work_group_name,
          isShow: activeRole?.work_groups.some(
            (role: any) => role.id === item.id
          ),
          checked: activeRole?.work_groups.some(
            (role: any) => role.id === item.id
          ),
          parentId: item.parent_work_group,
          items: []
        }

        if (!node.parentId) {
          rootNodes.push(node)
        }

        nodeMap[node.id] = node
      })

      inputData.forEach((item: any) => {
        if (item.parent_work_group && nodeMap[item.parent_work_group]) {
          nodeMap[item.parent_work_group].items.push(nodeMap[item.id])
        }
      })

      const result = manageShowMillerNodes(rootNodes)
      console.log('result', result)

      return rootNodes
    },
    [activeRole]
  )

  const manageShowMillerNodes = (rootNodes: ColumnProps[]) => {
    const updatedNodes = [...rootNodes]

    const handleMillerColumn = (nodes: ColumnProps[]) => {
      let isShowItem = true

      for (const item of nodes) {
        if (item.isShow && isShowItem && item.items.length > 0) {
          isShowItem = false
          item.isShow = true
        } else {
          item.isShow = false
        }

        if (item.items) {
          handleMillerColumn(item.items)
        }
      }
    }
    handleMillerColumn(updatedNodes)

    return updatedNodes
  }

  const handleToggle = useCallback(() => {
    setToggle(!toggle)
  }, [setToggle, toggle])

  const handleSelectedWorkgroup = useCallback(
    (columns: SelectedColumnProps[]) => {
      setSelectedWorkgroups(columns)
    },
    [setSelectedWorkgroups]
  )

  const handleAssignWorkgroup = useCallback(async () => {
    selectedWorkgroups.length === 0 ? setIsShow(true) : setIsShow(false)
    if (selectedWorkgroups.length > 0) {
      const result: ApiResultType<RoleType> =
        await bulkService.assignWorkgroupToRole({
          roleId: activeRole?.id ?? '',
          workgroupList: selectedWorkgroups.map((item) => item.id)
        })
      if (!result.is_error) {
        toast.success('Workgroups to this role are sucsessfully updated.')
        setSelectedWorkgroups([])
        handleNewRole()
        handleToggle()
      } else {
        toast.error(result.message)
      }
    }
  }, [activeRole, selectedWorkgroups, setIsShow])

  const handleCloseMillerColoumn = useCallback(() => {
    const _data = JSON.stringify(totalWorkgroups)
    const result = JSON.parse(_data)
    setWorkgroups(result)
    handleToggle()
  }, [setWorkgroups, totalWorkgroups, toggle, setToggle])

  return (
    <div className='workgroup-container'>
      {loading ? (
        <div className='d-flex justify-content-center'>
          <Spinner />
        </div>
      ) : (
        <div className='workgroup'>
          <div className='flex select-workgroup flex-column'>
            <div className='d-flex flex-column flex-1 bg-white p-1 rounded'>
              <div
                className='d-flex justify-content-between align-items-center select-container'
                onClick={handleToggle}
              >
                <span className='select-title'>
                  Select workgroup to add within{' '}
                  <span className='select-role-name'>
                    {' '}
                    {activeRole?.formatted_role_name}
                  </span>{' '}
                </span>
                <i className='fa-regular fa-chevron-down ' />
              </div>
              {toggle && (
                <div className='mt-2'>
                  <div className='d-flex mb-1 justify-content-between align-items-center miller-header'>
                    <span className='error-text'>
                      {isShow ? 'Please select atleast one workgroup' : ''}
                    </span>
                    <div className='d-flex gap-2'>
                      <Button
                        color='edit'
                        className='btn-sm'
                        onClick={handleAssignWorkgroup}
                        disabled={selectedWorkgroups.length === 0}
                      >
                        {' '}
                        Update
                      </Button>
                      <Button
                        color='cancel'
                        className='btn-sm'
                        onClick={handleCloseMillerColoumn}
                      >
                        {' '}
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div id='miller-column'>
                    <MillerColumns
                      columnData={workgroups}
                      getSelectedColoumn={(selectedColumns) =>
                        handleSelectedWorkgroup(selectedColumns)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AssignNewWorkgroupRole

import React, { useEffect, useState } from 'react'
// import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Col,
  Row,
  Label,
  Button,
  Form,
  FormFeedback,
  Input,
  Spinner
} from 'reactstrap'

import style from './ManageProfile.module.scss'
import {
  AuthService,
  UserService,
  UserType
} from '@digitalworkflow/dwloginclient'

import countries from '../../assets/json/countries.json'
import timezones from '../../assets/json/timezones.json'
import Select from '../select/select'
import { selectStylesForUserDetails } from '../select/manageUser.styles'
import { toast } from 'react-toastify'
import { checkSuperAdmin } from '../../utils/checkAdmin'
import { getTimezonesForCountry } from 'countries-and-timezones'

const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)
const userService = new UserService()

interface Props {
  userId: string
  userInfo: UserType | null
  getUser: () => void
  handleUpdateUser: (profile: UserType | undefined) => void
}

const UserDetails = ({
  userId,
  userInfo,
  getUser,
  handleUpdateUser
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [timezoneOptions, setTimezoneOptions] = useState(timezones)

  useEffect(() => {
    const permission = checkSuperAdmin()
    setIsAdmin(permission)
  }, [])
  const updateUserDetails = async (values: any) => {
    setIsSubmitting(true)
    try {
      const result = await userService.updateUserById(userId, values)
      if (result.is_error) {
        toast.error(result.message ?? 'Form Submission Error')
      } else {
        toast.success('User information is updated successfully')
      }
      getUser()
      setIsSubmitting(false)
      handleUpdateUser(result.data)
    } catch (e) {
      console.log(e)
      toast.error('Form Submission Error')
    }
  }

  const findTimezoneByUTC = (search?: any) => {
    if (search) {
      return timezones.find((timezone) => timezone.utc.includes(search)) ?? null
    }

    return null
  }

  function handleCountryTimezone(countryVal: any, isOnchnage: boolean = true) {
    // set timezone based on country selection
    const tz = getTimezonesForCountry(countryVal)
    const timezoneArr: any[] = []

    if (tz.length > 0) {
      let countryTzObj: any

      for (let tzc = 0; tzc < tz.length; tzc++) {
        countryTzObj = findTimezoneByUTC(tz[tzc]?.name)

        if (countryTzObj) {
          const exists = timezoneArr.some(
            (tz) => tz.value === countryTzObj.value
          )
          if (!exists) timezoneArr.push(countryTzObj)
        } // tz found
      } // for tzc

      if (timezoneArr.length) {
        setTimezoneOptions(timezoneArr)
        if (isOnchnage) handleValue('timezone', timezoneArr[0].value)
      } else {
        setTimezoneOptions(timezones)
      }
    } // country timezone found
  } // handle country timezone

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      email: userInfo?.email,
      phone: userInfo?.phone,
      job_title: userInfo?.job_title,
      location: userInfo?.location,
      timezone: userInfo?.timezone
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim().required('Please Enter Your First Name'),
      last_name: Yup.string().trim().required('Please Enter Your Last Name'),
      email: Yup.string().trim().required('Please Enter Your Email ')
    }),
    onSubmit: async (values) => {
      updateUserDetails(values)
    }
  })

  // at start
  useEffect(() => {
    if (userInfo?.location) {
      handleCountryTimezone(userInfo.location, false)
    }
  }, [])

  const handleCancel = () => {
    validation.resetForm()
  }

  const handleValue = (fieldName: string, value: string) => {
    validation.setFieldTouched(fieldName, true)
    validation.setFieldValue(fieldName, value)
  }

  const handleSetAuthenticationKey = async () => {
    try {
      setIsUpdating(true)
      const _result = await userService.setMagicKey(userId)
      if (_result && !_result.is_error) {
        toast.success(
          "User's authentication key has been created successfully!"
        )
      } else {
        toast.error(
          _result.message ?? "User's authentication key is not created."
        )
      }
      getUser()
      setIsUpdating(false)
      handleUpdateUser(_result.data)
    } catch (e) {
      console.log(e)
      toast.error('Form Submission Error')
    }
  }

  const handleMagicKeyUpdate = async () => {
    try {
      setIsUpdating(true)
      const _result = await userService.updateMagicKey(userId)
      if (_result && !_result.is_error) {
        toast.success(
          "User's authentication key has been updated successfully!"
        )
      } else {
        toast.error(
          _result.message ?? "User's authentication key is not updated."
        )
      }
      getUser()
      setIsUpdating(false)
      handleUpdateUser(_result.data)
    } catch (e) {
      console.log(e)
      toast.error('Form Submission Error')
    }
  }

  const handleMagicKeyDelete = async () => {
    try {
      setIsDeleting(true)
      const _result = await userService.deleteMagicKey(userId)
      if (_result && !_result.is_error) {
        toast.success(
          "User's authentication key has been deleted successfully!"
        )
      } else {
        toast.error(
          _result.message ?? "User's authentication key is not deleted."
        )
      }
      getUser()
      setIsDeleting(false)
      handleUpdateUser(_result.data)
    } catch (e) {
      console.log(e)
      toast.error('Form Submission Error')
    }
  }

  return (
    <div className={style.portalContainer}>
      <div className={style.gridPortalContainer}>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row className='mb-1'>
            <Col sm={3}>
              <Label htmlFor='first_name' className='col-form-label'>
                First Name
              </Label>
            </Col>
            <Col sm={9}>
              <Input
                type='text'
                className='form-control'
                id='first_name'
                placeholder='First Name'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.first_name || ''}
                invalid={
                  !!(
                    validation.touched.first_name &&
                    validation.errors.first_name
                  )
                }
              />

              {validation.touched.first_name && validation.errors.first_name ? (
                <FormFeedback type='invalid'>
                  {validation.errors.first_name}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className='mb-1'>
            <Col sm={3}>
              <Label htmlFor='last_name' className='col-form-label'>
                Last Name
              </Label>
            </Col>
            <Col sm={9}>
              <Input
                type='text'
                className='form-control'
                id='last_name'
                placeholder='Last Name'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.last_name || ''}
                invalid={
                  !!(
                    validation.touched.last_name && validation.errors.last_name
                  )
                }
              />

              {validation.touched.last_name && validation.errors.last_name ? (
                <FormFeedback type='invalid'>
                  {validation.errors.last_name}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className='mb-1'>
            <Col sm={3}>
              <Label htmlFor='email' className='col-form-label'>
                Email
              </Label>
            </Col>
            <Col sm={9}>
              <Input
                type='text'
                className='form-control'
                id='email'
                placeholder='Email'
                readOnly
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ''}
                invalid={
                  !!(validation.touched.email && validation.errors.email)
                }
              />

              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type='invalid'>
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className='mb-1'>
            <Col sm={3}>
              <Label htmlFor='phone' className='col-form-label'>
                Phone Number
              </Label>
            </Col>
            <Col sm={9}>
              <Input
                type='text'
                className='form-control'
                id='phone'
                placeholder='Phone Number'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.phone || ''}
                invalid={
                  !!(validation.touched.phone && validation.errors.phone)
                }
              />

              {validation.touched.phone && validation.errors.phone ? (
                <FormFeedback type='invalid'>
                  {validation.errors.phone}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          <Row className='mb-1'>
            <Col sm={3}>
              <Label htmlFor='job_title' className='col-form-label'>
                Job Title
              </Label>
            </Col>
            <Col sm={9}>
              <Input
                type='text'
                className='form-control'
                id='job_title'
                placeholder='Job Title'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.job_title || ''}
                invalid={
                  !!(
                    validation.touched.job_title && validation.errors.job_title
                  )
                }
              />

              {validation.touched.job_title && validation.errors.job_title ? (
                <FormFeedback type='invalid'>
                  {validation.errors.job_title}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          <Row className='mb-1'>
            <Col sm={3}>
              <Label htmlFor='location' className='col-form-label'>
                Country
              </Label>
            </Col>
            <Col sm={9}>
              <Select
                style={{
                  ...selectStylesForUserDetails
                }}
                value={{
                  ...countries.find(
                    (item: any) => item.value === validation.values.location
                  )
                }}
                onChange={(value: any) => {
                  handleValue('location', value.value)

                  // set timezone based on country selection
                  handleCountryTimezone(value.value)
                }}
                options={countries}
              />
              {validation.touched.location && validation.errors.location ? (
                <FormFeedback type='invalid'>
                  {validation.errors.location}
                </FormFeedback>
              ) : null}
              {/* <select
                className='form-control'
                id='location'
                placeholder='Country'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.location || ''}
                //   invalid={
                //     validation.touched.location && validation.errors.location
                //       ? true
                //       : false
                //   }
              >
                <option>Select</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select> */}

              {/* {validation.touched.location && validation.errors.location ? (
                <FormFeedback type='invalid'>
                  {validation.errors.location}
                </FormFeedback>
              ) : null} */}
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col sm={3}>
              <Label htmlFor='timezone' className='col-form-label'>
                Timezone
              </Label>
            </Col>
            <Col sm={9}>
              <Select
                style={{
                  ...selectStylesForUserDetails
                }}
                value={{
                  ...timezoneOptions.find(
                    (item: any) => item.value === validation.values.timezone
                  )
                }}
                onChange={(value: any) => handleValue('timezone', value.value)}
                options={timezoneOptions}
              />
              {validation.touched.timezone && validation.errors.timezone ? (
                <FormFeedback type='invalid'>
                  {validation.errors.timezone}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className='mb-1'>
            {isAdmin && (
              <>
                <Col sm={3}>
                  <Label htmlFor='Key' className='col-form-label'>
                    Key
                  </Label>
                </Col>
                <Col sm={9}>
                  {userInfo?.magic_key ? (
                    <div className='d-flex gap-2'>
                      <div className={style.magicKey}>
                        <Input
                          type={isShow ? 'text' : 'password'}
                          name='magic_key'
                          disabled
                          bsSize='sm'
                          value={userInfo?.magic_key}
                          width='130px'
                        ></Input>
                        <div
                          onClick={() => setIsShow((prev) => !prev)}
                          className={style.magicKeyDisplay}
                        >
                          <i
                            className={`fa-solid ${
                              isShow ? 'fa-eye-slash' : 'fa-eye'
                            }`}
                          ></i>
                        </div>
                      </div>
                      <Button
                        type='button'
                        color='primary'
                        className='btn-sm'
                        disabled={isUpdating || isDeleting}
                        onClick={handleMagicKeyUpdate}
                      >
                        Update
                        {isUpdating && <Spinner className={style.spinner} />}
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        className='btn-sm'
                        onClick={handleMagicKeyDelete}
                        disabled={isUpdating || isDeleting}
                      >
                        Delete
                        {isDeleting && <Spinner className={style.spinner} />}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type='button'
                      color='primary'
                      onClick={handleSetAuthenticationKey}
                      className='btn-sm mb-2'
                    >
                      Set Authentication Key
                      {isUpdating && <Spinner className={style.spinner} />}
                    </Button>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Row className='mb-1'>
            <Col className='d-flex gap-2'>
              <Button
                type='submit'
                color='edit'
                disabled={!validation.dirty}
                className='btn-sm'
              >
                <i className='fal fa-edit me-2' />
                Update
                {isSubmitting && <Spinner className={style.spinner} />}
              </Button>
              <Button
                type='button'
                color='cancel'
                onClick={handleCancel}
                disabled={!validation.dirty}
                className='btn-sm'
              >
                <i className='fa fa-times me-2' />
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
        {/* TODO : Need to remove the below button section */}
        {/* <div className={`flex gap-2 ${style.updateButtons}`}>
            <button
              className={`${style.buttons} ${style.edit} ${
                !isDisabled ? style.disable : ''
              }`}
              onClick={handleUpdate}
            >
              <i className={`fal fa-edit ${style.icon}`} />
              Update
              {isSubmitting && <Spinner className={style.spinner} />}
            </button>
            <button
              className={`${style.buttons} ${style.deactivate} ${
                !isDisabled ? style.cancelDisbale : ''
              }`}
              onClick={handleCancel}
            >
              <i className={`fa fa-times ${style.icon}`} />
              Cancel
            </button>
          </div> */}
      </div>
    </div>
  )
}
export default UserDetails

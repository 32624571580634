/* eslint-disable camelcase */
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import {
  AuthService,
  FeatureService,
  FeatureType,
  UserService,
  UserType
} from '@digitalworkflow/dwloginclient'
import ToggleButton from '../ToggleButton/ToggleButton'
import { Button, Spinner } from 'reactstrap'
import style from './ManageProfile.module.scss'
import '../../style/roleContianer.scss'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { compareArrays } from '../../utils/compareArray'
import { sortComparator } from '../../utils/sortArray'
import { floatingButton } from '../../utils/floatingButton'
import { checkSuperAdmin } from '../../utils/checkAdmin'
import { LocalSettings } from '../../utils/LocalSettings'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'

interface Props {
  userInfo: UserType | null
  getUser: () => void
  userProfile?: boolean
}

const authService = AuthService.instance()
FeatureService.setAuthServiceInstance(authService)
UserService.setAuthServiceInstance(authService)

const featureService = new FeatureService()
const userService = new UserService()

const adminAccessFeatures = [
  'Manage Cronjob History',
  'Manage Portals',
  'Manage Claims Mapping',
  // 'Manage Email Templates',
  'Manage System Messages'
]
const userFeatureList = ['Manage Users', 'Manage Roles', 'Manage Workgroups']

const FeatureAccess = ({ userInfo, getUser, userProfile = false }: Props) => {
  const { setFeatureAccessSidebarUpdated } = useAuth()
  const navigate = useNavigate()

  const [featureAccessList, setFeatureAccessList] = useState<FeatureType[]>([])
  const [userAccessFeature, setUserAccessFeature] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const gridRef = useRef<any>(null)
  useEffect(() => {
    getAllFeatures()
  }, [])

  useEffect(() => {
    if (userInfo) {
      setUserAccessFeature([
        ...(userInfo?.features_enabled ?? ([] as string[]))
      ])
    }
  }, [userInfo?.features_enabled])

  useEffect(() => {
    if (userAccessFeature.length === featureAccessList.length) {
      floatingButton('floatingAccessToggleButton', true)
    } else {
      floatingButton('floatingAccessToggleButton', false)
    }
  }, [userAccessFeature, featureAccessList])

  const isDisabled = useMemo(() => {
    if (userInfo) {
      return compareArrays(userInfo?.features_enabled ?? [], userAccessFeature)
    } else {
      return true
    }
  }, [userInfo, userAccessFeature])

  const isToggleButtonDisabled = useMemo(() => {
    return userProfile ? !checkSuperAdmin() : false
  }, [userProfile, checkSuperAdmin])

  const getAllFeatures = useCallback(async () => {
    setIsLoading(true)
    const result = await featureService.getAllFeatures()
    setIsLoading(false)
    const data = result.data?.sort((a, b) => {
      if (a.feature_name === 'Manage Users') {
        return -1
      } else if (b.feature_name === 'Manage Users') {
        return 1
      } else {
        return 0
      }
    })
    const userData = JSON.parse(LocalSettings.getUser() ?? '')
    const filterData =
      checkSuperAdmin() || userProfile
        ? data
        : data?.filter((item) =>
            userData.features_enabled.some(
              (feature: any) => feature.feature_name === item.feature_name
            )
          )
    userFeatureList.forEach((element) => {
      if (!filterData?.some((item) => item.feature_name === element)) {
        const featureFound = data?.find(
          (feature) => feature.feature_name === element
        )
        featureFound && filterData?.push(featureFound)
      }
    })
    setFeatureAccessList(filterData ?? [])
  }, [setFeatureAccessList, setIsLoading])

  const handleBulkActionToggle = (value: boolean) => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      const filteredRows = gridRef.current.api.getModel().rowsToDisplay
      const _allAccessFeaturesIds: string[] = filteredRows.map(
        (rowData: any) => rowData.data.id
      )
      if (value) {
        setUserAccessFeature(_allAccessFeaturesIds)
      } else {
        setUserAccessFeature([])
      }
    }
  }

  const handleToggleActive = useCallback(
    (data: any, isActive: boolean) => {
      if (
        userProfile &&
        !checkSuperAdmin() &&
        adminAccessFeatures.some((item) => item === data.feature_name)
      ) {
        toast.error('You are not allowed to modify this Feature Access')
        setUserAccessFeature((items) => [...items])
        return
      }
      if (isActive) {
        setUserAccessFeature((items) => [...items, data.id])
      } else {
        setUserAccessFeature((userFeature) =>
          userFeature.filter((r) => r !== data.id)
        )
      }
    },
    [userAccessFeature]
  )

  const columnDefs = useMemo(() => {
    return [
      {
        field: 'feature_name',
        headerName: 'Feature Name',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        flex: 3,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        comparator: sortComparator(null)
      },
      {
        field: 'id',
        headerName: 'Action',
        filter: 'agNumberColumnFilter',
        resizable: true,
        minWidth: 120,
        maxWidth: 120,
        flex: 1,
        floatingFilter: !userProfile,
        ...(!userProfile && {
          floatingFilterComponent: ToggleButton,
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            id: 'floatingAccessToggleButton',
            onChange: (e: any) => handleBulkActionToggle(e.target.checked)
          }
        }),
        cellRenderer: (params: any) => (
          <ToggleButton
            name='feature_access'
            isChecked={userAccessFeature.includes(params.data.id)}
            disabled={isToggleButtonDisabled}
            onChange={(event: any) => {
              handleToggleActive(params.data, event.target.checked)
            }}
          />
        )
      }
    ]
  }, [userAccessFeature])
  const handleUpdate = useCallback(async () => {
    if (userInfo?.id) {
      setIsSubmitting(true)
      try {
        const _result: ApiResultType<UserType> =
          await userService.updateUserById(userInfo?.id, {
            features_enabled: userAccessFeature
          })
        const userData = JSON.parse(LocalSettings.getUser() ?? '')
        if (userData.id === userInfo.id && checkSuperAdmin()) {
          userData.features_enabled = featureAccessList.filter((item) =>
            userAccessFeature.includes(item?.id ?? '')
          )

          LocalSettings.setUser(JSON.stringify(userData))
          // update sidebar
          setFeatureAccessSidebarUpdated((prev) => ++prev)
          const manageUserId =
            featureAccessList.find(
              (item) => item.feature_name === 'Manage Users'
            )?.id ?? ''
          if (manageUserId) {
            if (!userAccessFeature.some((item) => item === manageUserId)) {
              navigate('/hub/profile')
            }
          }
        }
        if (!_result.is_error) {
          toast.success('Feature Access have been successfully updated.')
        } else {
          toast.error(_result.message ?? 'Feature Access Update Error')
        }
      } catch (e) {
        console.log(e)
        toast.error('Feature Access Update Error')
      }

      getUser()
      setIsSubmitting(false)
    }
  }, [userAccessFeature, featureAccessList])

  return (
    <div className={style.portalContainer}>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className={style.gridPortalContainer}>
          <div className='mb-2 mt-2 d-flex justify-content-end'>
            {!isToggleButtonDisabled && (
              <div className='d-flex gap-2'>
                <Button
                  type='submit'
                  color='edit'
                  className='btn-sm'
                  disabled={isDisabled}
                  onClick={handleUpdate}
                >
                  <i className='fal fa-edit me-2' />
                  Update
                  {isSubmitting && <Spinner className={style.spinner} />}
                </Button>
                <Button
                  type='button'
                  color='cancel'
                  className='btn-sm'
                  disabled={isDisabled}
                  onClick={() =>
                    setUserAccessFeature(userInfo?.features_enabled ?? [])
                  }
                >
                  <i className='fa fa-times me-2' />
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <LoginPortalGrid
            columnDefs={columnDefs}
            gridRef={gridRef}
            rowData={featureAccessList}
            overlayLoadingTemplate='Processing...'
          />
        </div>
      )}
    </div>
  )
}
export default FeatureAccess

/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import ToggleButton from '../ToggleButton/ToggleButton'
import { Button, Spinner, Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'
import style from './ManageProfile.module.scss'
import '../../style/roleContianer.scss'
import {
  AuthService,
  UserService,
  WorkgroupService,
  UserType,
  WorkgroupType,
  AccessRequestService,
  AccessRequestType,
  FeatureService
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { sortComparator } from '../../utils/sortArray'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { compareArrays } from '../../utils/compareArray'

interface Props {
  userInfo: any | null
  getUser: () => void
  isAllowedToModify?: boolean
  handleCopyFrom?: (userId: string) => void
  myProfile?: boolean
}
type SortDirection = 'asc' | 'desc' | null
const Workgroups = ({
  userInfo,
  getUser,
  isAllowedToModify = true,
  handleCopyFrom,
  myProfile = false
}: Props) => {
  console.log('myProfile', myProfile)
  const workgroupsRef = useRef<WorkgroupType[]>([])
  const [userWorkgroups, setUserWorkgroups] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tooltipContent, setTooltipContent] = useState('')
  const [tooltipTarget, setTooltipTarget] = useState<HTMLElement | null>(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [bulkActionToggle, setBulkActionToggle] = useState<boolean>(false)
  const accessRequestsRef = useRef<AccessRequestType[]>([])
  const gridRef = useRef<any>(null)

  const authService = AuthService.instance()
  WorkgroupService.setAuthServiceInstance(authService)
  UserService.setAuthServiceInstance(authService)
  AccessRequestService.setAuthServiceInstance(authService)
  FeatureService.setAuthServiceInstance(authService)
  const workgroupService = new WorkgroupService()
  const userService = new UserService()
  const accessRequestService = new AccessRequestService()
  const featureService = new FeatureService()

  const isDisabled = useMemo(() => {
    return compareArrays(
      userInfo?.reasons_list.map((item: any) => item.workgroup_id) as string[],
      userWorkgroups
    )
  }, [userInfo, userWorkgroups])
  const getAllWorkgroups = useCallback(async () => {
    try {
      setIsLoading(true)

      const groupsRes = await workgroupService.getAllWorkgroup('restricted')
      if (!groupsRes.is_error) {
        if (!isAllowedToModify) {
          // Fetch workgroup access requests
          const result = await accessRequestService.getAll()
          if (result && !result.is_error) {
            const filteredData = result.data?.filter(
              (request) => request.status === 'pending'
            )
            accessRequestsRef.current = filteredData ?? []
          }
        }

        const data = groupsRes.data ?? []
        if (data.length > 0) {
          const sortData = [...data].sort(
            sortComparator('formatted_workgroup_name')
          )
          workgroupsRef.current = sortData
        } else {
          workgroupsRef.current = []
        }
        if (
          userInfo?.work_groups.length > 0 &&
          userInfo?.work_groups.length === workgroupsRef.current.length
        ) {
          handleBulkActionToggle(true)
        }
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [setIsLoading])

  useEffect(() => {
    getAllWorkgroups()
  }, [])

  useEffect(() => {
    if (userInfo?.reasons_list.length > 0) {
      const _userList = userInfo?.reasons_list.map(
        (item: any) => item.workgroup_id
      )
      setUserWorkgroups([...(_userList as string)])
    }
  }, [userInfo?.reasons_list])

  const handleBulkActionToggle = (value: boolean) => {
    setBulkActionToggle(value)
    if (gridRef && gridRef.current && gridRef.current.api) {
      const filteredRows = gridRef.current.api.getModel().rowsToDisplay
      const _allWorkgroupsIds: string[] = filteredRows.map(
        (rowData: any) => rowData.data.id
      )
      if (value) {
        setUserWorkgroups(_allWorkgroupsIds)
      } else {
        setUserWorkgroups([])
      }
    }
  }
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const handleMouseOver = (e: any) => {
    const target = e.target as HTMLElement
    setTooltipContent(target.innerText)
    setTooltipTarget(target)
    toggle()
  }

  const handleMouseOut = () => {
    setTooltipTarget(null)
    toggle()
  }

  const columnDefs = useMemo(() => {
    if (isAllowedToModify) {
      return [
        {
          field: 'formatted_workgroup_name',
          headerName: 'Workgroup Name',
          sortable: true,
          unSortIcon: true,
          resizable: true,
          flex: 3,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          comparator: sortComparator(null)
        },
        {
          field: 'action',
          headerName: 'Action',
          sort: 'desc' as SortDirection,
          filter: 'agNumberColumnFilter',
          floatingFilter: true,
          resizable: true,
          minWidth: 120,
          maxWidth: 120,
          flex: 1,
          floatingFilterComponent: ToggleButton,
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            isChecked: bulkActionToggle,
            onChange: (e: any) => handleBulkActionToggle(e.target.checked)
          },
          valueGetter: (params: any) => userWorkgroups.includes(params.data.id),
          cellRenderer: (params: any) => (
            <ToggleButton
              name='workgroup'
              isChecked={userWorkgroups.includes(params.data.id)}
              onChange={(event: any) =>
                handleToggleActive(params.data.id, event.target.checked)
              }
            />
          )
        },
        {
          field: 'reason',
          headerName: 'Reason',
          sortable: true,
          unSortIcon: true,
          resizable: true,
          floatingFilter: true,
          flex: 4,
          filter: 'agTextColumnFilter',
          filterValueGetter: (params: any) =>
            'Inherited via ' +
            userInfo?.reasons_list
              ?.find((item: any) => item.workgroup_id === params.data.id)
              ?.reason.replace('Member of', ''),
          comparator: sortComparator(null),
          cellRenderer: (params: any) => (
            <>
              {userInfo?.reasons_list?.find(
                (item: any) => item.workgroup_id === params.data.id
              )?.reason && (
                <span
                  className={style.reason}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  Inherited via
                  {userInfo?.reasons_list
                    ?.find((item: any) => item.workgroup_id === params.data.id)
                    ?.reason.replace('Member of', '')}{' '}
                </span>
              )}
            </>
          )
        }
      ]
    } else {
      return [
        {
          field: 'formatted_workgroup_name',
          headerName: 'Workgroup Name',
          sortable: true,
          unSortIcon: true,
          resizable: true,
          flex: 1,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          comparator: sortComparator(null)
        },
        {
          field: 'reason',
          headerName: 'Active',
          sort: 'desc' as SortDirection,
          unSortIcon: true,
          resizable: true,
          flex: 1,
          filter: 'agTextColumnFilter',
          filterValueGetter: (params: any) =>
            'Inherited via ' +
            userInfo?.reasons_list
              ?.find((item: any) => item.workgroup_id === params.data.id)
              ?.reason.replace('Member of', ''),
          valueGetter: (params: any) => userWorkgroups.includes(params.data.id),
          cellRenderer: (params: any) =>
            userWorkgroups.includes(params.data.id) ? (
              <div>
                <i className='fa fa-check mr-1' color='green' />
                {userInfo?.reasons_list?.find(
                  (item: any) => item.workgroup_id === params.data.id
                )?.reason && (
                  <span
                    className={style.reason}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    Inherited via
                    {userInfo?.reasons_list
                      ?.find(
                        (item: any) => item.workgroup_id === params.data.id
                      )
                      ?.reason.replace('Member of', '')}{' '}
                  </span>
                )}
              </div>
            ) : (
              <div>
                {accessRequestsRef.current.find(
                  (request) =>
                    request.requester === userInfo?.id &&
                    request.workgroup === params.data.id
                ) ? (
                  <span>Access Request Pending</span>
                ) : (
                  <span
                    className={style.accessRequestBtn}
                    onClick={() => handleRequestAccess(params.data.id)}
                  >
                    Request Access
                  </span>
                )}
              </div>
            )
        }
      ]
    }
  }, [userWorkgroups, isAllowedToModify, bulkActionToggle])

  const handleRequestAccess = async (id: string) => {
    gridRef.current!.api.showLoadingOverlay()
    const _result = await accessRequestService.makeRequest(id)
    if (_result && !_result.is_error) {
      // Fetch workgroup access requests
      const result = await accessRequestService.getAll('pending', 'all')
      if (result && !result.is_error) {
        accessRequestsRef.current = result.data ?? []
        gridRef.current!.api.redrawRows()
      }
      toast.success(_result.data.message)
    } else {
      toast.error(_result.message ?? 'An error occured!')
    }
    gridRef.current!.api.hideOverlay()
  }

  const handleToggleActive = useCallback(
    (id: string, isActive: boolean) => {
      if (isActive) {
        setUserWorkgroups((userWorkgroups) => [...userWorkgroups, id])
      } else {
        setUserWorkgroups((userWorkgroups) =>
          userWorkgroups.filter((r) => r !== id)
        )
      }
    },
    [userWorkgroups]
  )

  const handleUpdate = useCallback(async () => {
    if (userInfo?.id) {
      setIsSubmitting(true)
      try {
        const _data: any = {
          work_groups: userWorkgroups
        }

        const adminIds: any = workgroupsRef.current
          .filter((item) => {
            return (
              item.formatted_workgroup_name ===
                'DigitalWorkflow :: LoginPortal :: Admin' ||
              item.formatted_workgroup_name === 'DigitalWorkflow :: Admin'
            )
          })
          .map((workgroup) => workgroup.id)

        if (
          userWorkgroups.some((userWorkgroupId) =>
            adminIds.includes(userWorkgroupId)
          )
        ) {
          const result = await featureService.getAllFeatures()
          if (result.data && result.data?.length > 0) {
            _data.features_enabled = result.data.map((item) => item.id)
          }
        } else {
          /* Check admin workgroup exists in previous data and doesnot exsit in current
           user workgroups then remove feature-access */
          if (
            userInfo.work_groups.some((userWorkgroupId: string) =>
              adminIds.includes(userWorkgroupId)
            ) &&
            !userWorkgroups.some((userWorkgroupId) =>
              adminIds.includes(userWorkgroupId)
            )
          ) {
            _data.features_enabled = []
          }
        }
        const _result: ApiResultType<UserType> =
          await userService.updateUserById(userInfo?.id, _data)
        if (!_result.is_error) {
          toast.success('Workgroups have been successfully updated')
        } else {
          toast.error(_result.message ?? 'Workgroups Update Error')
        }
      } catch (e) {
        console.log(e)
        toast.error('Workgroups Update Error')
      }

      getUser()
      setIsSubmitting(false)
    }
  }, [userWorkgroups, workgroupsRef.current, userInfo, setIsSubmitting])

  const handleCancel = () => {
    setUserWorkgroups(userInfo?.work_groups ?? [])
  }

  const workgroupData = useMemo(() => {
    return !myProfile || isAllowedToModify
      ? workgroupsRef.current
      : workgroupsRef.current.filter((item: any) =>
          userWorkgroups.includes(item.id)
        )
  }, [myProfile, workgroupsRef.current])

  return (
    <div className={style.portalContainer}>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className={style.gridPortalContainer}>
          {isAllowedToModify && (
            <div className='mb-2 mt-2 d-flex justify-content-end'>
              <div className='d-flex gap-2'>
                <Button
                  type='button'
                  color='primary'
                  className='btn-sm'
                  onClick={() => handleCopyFrom && handleCopyFrom(userInfo.id)}
                >
                  <i className='fal fa-copy me-2' />
                  Copy From
                </Button>
                <Button
                  type='submit'
                  color='edit'
                  className='btn-sm'
                  onClick={handleUpdate}
                  disabled={isDisabled}
                >
                  <i className='fal fa-edit me-2' />
                  Update
                  {isSubmitting && <Spinner className={style.spinner} />}
                </Button>
                <Button
                  type='button'
                  color='cancel'
                  className='btn-sm'
                  disabled={isDisabled}
                  onClick={handleCancel}
                >
                  <i className='fa fa-times me-2' />
                  Cancel
                </Button>
              </div>
            </div>
          )}
          <LoginPortalGrid
            columnDefs={columnDefs}
            gridRef={gridRef}
            rowData={workgroupData}
            overlayLoadingTemplate='Processing...'
          />
        </div>
      )}
      {tooltipTarget && (
        <Tooltip isOpen={tooltipOpen} target={tooltipTarget} toggle={toggle}>
          {tooltipContent}
        </Tooltip>
      )}
    </div>
  )
}
export default Workgroups

import React, { useState, useCallback, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import {
  AuthService,
  UserService,
  WorkgroupService,
  WorkgroupType,
  UserType
} from '@digitalworkflow/dwloginclient'
import { fetchRsPermissions } from '../../services/fetchRsPermissions'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'

import style from './ManageProfile.module.scss'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'

interface Props {
  userInfo: UserType | null
}

interface RsPermissionsType {
  rsPermission: string
  mappedTo: string
}

const columnDefs = [
  {
    field: 'rsPermission',
    headerName: 'RS Permission',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    floatingFilter: true
  },
  {
    field: 'mappedTo',
    headerName: 'Mapped to',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    floatingFilter: true
  }
]

const ImportTools: React.FC<Props> = ({ userInfo }: Props) => {
  const [rsPermissionsData, setRsPermissionsData] = useState<
    RsPermissionsType[] | null
  >(null)
  const [workgroups, setWorkgroups] = useState<WorkgroupType[]>([])
  const [newUserWorkgroups, setNewUserWorkgroups] = useState<string[]>([])
  const [isImporting, setIsImporting] = useState<boolean>(false)

  const authService = AuthService.instance()
  WorkgroupService.setAuthServiceInstance(authService)
  UserService.setAuthServiceInstance(authService)
  const workgroupService = new WorkgroupService()
  const userService = new UserService()

  const getAllWorkgroups = useCallback(async () => {
    try {
      const groupsRes = await workgroupService.getAllWorkgroup()
      if (!groupsRes.is_error) {
        setWorkgroups(groupsRes.data ?? [])
      } else {
        toast.error('Error retrieving workgroups!')
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    getAllWorkgroups()
  }, [getAllWorkgroups, userInfo])

  useEffect(() => {
    if (isImporting) {
      const updateUserWorkgroups = async () => {
        if (userInfo?.id) {
          // Update user's work_groups
          const _result: ApiResultType<UserType> =
            await userService.updateUserById(userInfo.id, {
              work_groups: newUserWorkgroups
            })
          if (!_result.is_error) {
            setIsImporting(false)
          } else {
            toast.error(_result.message ?? 'Workgroups Update Error')
          }
        }
      }

      updateUserWorkgroups()
    }
  }, [rsPermissionsData])

  const getFormattedWorkGroupName = useCallback(
    /**
     * Create new workgroup in DB if workgroup is not exist
     * @param bgisRsId parent work group ID
     * @param description user's description
     * @returns
     */
    async (bgisRsId: string, description: string) => {
      const FormattedWorkgroupName = 'BGIS :: RS :: ' + description
      const existWorkgroup = workgroups.find((el: WorkgroupType) => {
        const string1 = (el.formatted_workgroup_name ?? '')
          .replace(/ /g, '')
          .toLowerCase()
        const string2 = FormattedWorkgroupName.replace(/ /g, '').toLowerCase()
        return string1 === string2
      })

      if (existWorkgroup?.id) {
        const id = existWorkgroup.id
        setNewUserWorkgroups((prev) => [...prev, id])
        return FormattedWorkgroupName
      } else {
        const _workgroups = {
          work_group_name: description,
          parent_work_group: bgisRsId,
          approver_group_name: bgisRsId,
          work_group_type: 'SystemGroup',
          description: ''
        }
        //  Create a new workgroup
        const result = await workgroupService.createWorkgroup(_workgroups)
        if (result.data?.id) {
          const id = result.data.id
          setNewUserWorkgroups((prev) => [...prev, id])
        }
        return result.data?.formatted_workgroup_name ?? ''
      }
    },
    [workgroups]
  )

  const handleClick = useCallback(async () => {
    if (!userInfo?.email.toLowerCase().includes('@bgis.com')) {
      toast.error('No permissions are found for this user.')
      return
    }
    setIsImporting(true)
    const bgisRsId = workgroups
      .filter(
        (el: WorkgroupType) => el.formatted_workgroup_name === 'BGIS :: RS'
      )
      .map((el) => el.id)[0]

    if (bgisRsId) {
      try {
        const response = await fetchRsPermissions(userInfo.email)
        // Extract unique descriptions from the response data
        const uniqueDescriptionData = Array.from(
          new Set(response?.data.hrms.map((el: any) => el.DESCRIPTION))
        ) as string[]
        // Create RS Permissions data array
        const data = await Promise.all(
          uniqueDescriptionData.map(async (description: string) => ({
            rsPermission: description,
            mappedTo: await getFormattedWorkGroupName(bgisRsId, description)
          }))
        )
        setRsPermissionsData(data)
      } catch (e) {
        console.log(e)
        toast.error('Workgroups Update Error')
      }
    } else {
      toast.error(
        "The parent workgroup BGIS :: RS doesn't exist. Contact Workflow Admin to set it up before importing RS Permissions"
      )
    }
  }, [getFormattedWorkGroupName, userInfo, workgroups])

  return (
    <div className={style.portalContainer}>
      <div className={style.gridPortalContainer}>
        <div>
          {workgroups.length ? (
            <Button
              type='button'
              color='edit'
              className='btn-sm'
              onClick={handleClick}
              disabled={isImporting}
            >
              Import RS Permissions
            </Button>
          ) : (
            <Spinner />
          )}
        </div>
        {isImporting ? (
          <Spinner />
        ) : (
          rsPermissionsData && (
            <LoginPortalGrid
              columnDefs={columnDefs}
              rowData={rsPermissionsData}
            />
          )
        )}
      </div>
    </div>
  )
}

export default ImportTools

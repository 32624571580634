import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import {
  AuthService,
  PortalService,
  PortalType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { Spinner } from 'reactstrap'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { checkSuperAdmin } from '../../utils/checkAdmin'
import { LocalSettings } from '../../utils/LocalSettings'

const defaulColDef = {
  sortable: true,
  unSortIcon: true,
  filter: 'agTextColumnFilter',
  minWidth: 140,
  floatingFilter: true
}
interface IPortalsList {
  isAdmin?: boolean
  handleManagePortal: (value: PortalType) => void
}

const PortalsList = forwardRef((props: IPortalsList, ref) => {
  const { handleManagePortal } = props
  const authService = AuthService.instance()

  PortalService.setAuthServiceInstance(authService)
  const portalService = new PortalService()
  const [portals, setPortals] = useState<PortalType[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getAllPortals()
  }, [])
  useImperativeHandle(ref, () => ({
    getAllPortals
  }))

  const getAllPortals = useCallback(async () => {
    setLoading(true)
    const _result: ApiResultType<PortalType[]> =
      await portalService.getAllPortals('restricted')
    setLoading(false)
    if (!_result.is_error) {
      const userData = JSON.parse(LocalSettings.getUser() ?? '')
      const _data = checkSuperAdmin()
        ? _result.data
        : _result.data?.filter((item) =>
            userData.portals_enabled.some(
              (portal: any) => portal.id === item.id
            )
          )
      setPortals(_data ?? [])
    }
  }, [setPortals, setLoading])

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'portal_name',
      flex: 2,
      resizable: true
    },
    {
      field: 'login_portal_id',
      headerName: 'Login Portal ID',
      flex: 2,
      resizable: true
    },
    {
      field: 'redirection_location_after_success',
      headerName: 'Success Redirection URL',
      flex: 3,
      resizable: true
    },
    {
      field: 'action',
      headerName: 'Action',
      filter: '',
      sortable: false,
      flex: 1,
      resizable: true,
      cellRenderer: (params: any) => (
        <div
          className='action-text'
          onClick={() => handleManagePortal(params.data)}
        >
          Manage Portal
        </div>
      )
    }
  ]
  return (
    <div style={{ height: '100%' }}>
      {loading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <LoginPortalGrid
          rowData={portals}
          defaulColDef={defaulColDef}
          columnDefs={columnDefs}
        />
      )}
    </div>
  )
})
export default PortalsList

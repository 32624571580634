import React, { useCallback, useEffect, useRef } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import ManageProfile from '../../components/ManageProfile/ManageProfile'
import BulkAction from '../../container/ManageUser/BulkAction'
import placeholderImage from '../../assets/images/placeholder.png'
import { RcGroup } from '../../components/RcGroup'
import Users from '../../components/Users/Users'
import { Button } from 'reactstrap'
import { exportData } from '../../utils/exportData'
import TabClose from '../../components/TabClose'
import { addFloatBox, getTab, removeRCTab } from '../../lib/rcDock-lib'
import { PanelData } from 'rc-dock'

import {
  AuthService,
  UserService,
  UserType
} from '@digitalworkflow/dwloginclient'
import { getSize } from '../../utils/getSize'

const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)

const ManageUser = () => {
  const userListRef = useRef<any>(null)
  const dockLayoutRef = useRef<DockLayout | null>(null)

  useEffect(() => {
    handleManageProfileDisplay('none')
  }, [])

  const handleManageProfileDisplay = useCallback((state: string) => {
    const manageProfilePanel = document.querySelector(
      '[data-dockid="manageProfile"]'
    ) as HTMLElement
    if (manageProfilePanel) {
      manageProfilePanel.style.display = state
    }
  }, [])

  const handleUpdateUser = async (profile: UserType | undefined) => {
    if (userListRef && userListRef.current) {
      await userListRef.current.getAllUsers()
    }
    if (profile) {
      if (dockLayoutRef.current?.find('float_manage_profile')) {
        handleEditProfile(profile, true)
      } else {
        handleEditProfile(profile, false)
      }
    }
  }
  const getTabData = (profile: UserType) => {
    return {
      id: 'tabs' + profile.id,
      title: (
        <>
          <div className='user-tab-header'>
            <img src={placeholderImage} />
            <span>
              {profile.first_name} {profile.last_name}
            </span>
          </div>
          <TabClose dockLayoutRef={dockLayoutRef} id={'tabs' + profile.id} />
        </>
      ),
      content: (
        <ManageProfile
          id={profile.id ?? `float_manage_profile`}
          handleUpdateUser={handleUpdateUser}
        />
      ),
      group: 'close-all'
    }
  }
  const handleEditProfile = (profile: UserType, floating: boolean) => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const _dockLayout = dockLayoutRef.current

      if (!floating && dockLayoutRef.current?.find('float_manage_profile')) {
        removeRCTab(dockLayoutRef, 'float_manage_profile')
      }

      if (floating) {
        // show floatbox
        addFloatBox(
          dockLayoutRef,
          'float_manage_profile',
          getTab(
            'float_manage_profile',
            'Manage Profile',
            dockLayoutRef,
            () => (
              <ManageProfile
                id={profile.id ?? ''}
                handleUpdateUser={handleUpdateUser}
              />
            ),
            700,
            200,
            800,
            50
          )
        )
      } else {
        if (_dockLayout.state.layout.maxbox?.children) {
          const { children } = _dockLayout.state.layout.maxbox

          if (children.length > 0) {
            const panelData = _dockLayout.find('usersSection')
            _dockLayout.dockMove(panelData as any, null, 'maximize')
          }
        }

        if (!_dockLayout.find('tabs' + profile.id)) {
          if (_dockLayout.find('manageProfile')) {
            _dockLayout.dockMove(getTabData(profile), 'manageProfile', 'middle')
          }
        } else {
          // updating the same tab
          _dockLayout.updateTab('tabs' + profile.id, getTabData(profile))
        }
        handleManageProfileDisplay('block')
      } // else to floating
    }
  }

  const userSections = [
    {
      mode: 'horizontal',
      children: [
        {
          id: 'usersSection',
          tabs: [
            {
              id: 'users',
              title: 'Users',
              content: (
                <Users
                  handleEditProfile={handleEditProfile}
                  ref={userListRef}
                />
              ),
              group: 'close-all'
            },
            {
              id: 'bulk_action',
              title: 'Bulk Action',
              content: (
                <BulkAction
                  userListRef={userListRef}
                  layoutRef={dockLayoutRef}
                />
              ),
              group: 'close-all'
            }
          ],
          group: 'close-all'
        },
        {
          id: 'manageProfile',
          panelLock: true,
          tabs: [],
          group: 'close-all'
        }
      ]
    }
  ]

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          size: getSize(2250, 1280),
          mode: 'vertical',
          children: [
            {
              tabs: [
                {
                  id: 'description',
                  title: 'MANAGE USERS',
                  content: (
                    <div className='p-2'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h6>MANAGE USERS</h6>
                        <Button
                          type='button'
                          color='add'
                          className='btn-sm mb-1'
                          style={{
                            float: 'right',
                            marginBottom: '2px',
                            marginRight: '17px'
                          }}
                          onClick={() => exportData(`users/export`, 'users')}
                        >
                          Export Users
                        </Button>
                      </div>
                      <p className='descripton'>
                        The Manage Users page displays a list of users and
                        provides information such as the name, job title, last
                        login, email address, portal access, workgroup
                        assignments and role. You can also perform tasks such as
                        adding, editing, changing status Activate/Deactivate for
                        the user.
                      </p>
                    </div>
                  ),
                  group: 'headless'
                }
              ]
            }
          ]
        },
        {
          mode: 'horizontal',
          children: userSections as any
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  const handleLayoutChange = useCallback(
    async (layout?: any, currentTabId: string = '') => {
      if (currentTabId === 'users') {
        if (userListRef && userListRef.current) {
          await userListRef.current.getAllUsers()
        }
      }
      if (currentTabId === 'bulk_action') {
        handleManageProfileDisplay('none')
      }
      const handleTabsCheck = () => {
        const _dockLayout = dockLayoutRef.current

        if (_dockLayout?.find('manageProfile')) {
          const { tabs } = _dockLayout?.find('manageProfile') as PanelData
          if (tabs && tabs.length === 0) {
            if (
              layout.maxbox?.children[0] &&
              layout.maxbox?.children[0].id === 'manageProfile'
            ) {
              const panelData = _dockLayout.find('manageProfile')
              _dockLayout.dockMove(panelData as PanelData, null, 'maximize')
            } else {
              setTimeout(() => {
                handleManageProfileDisplay('none')
              }, 0)
            }
          }
        }
      }

      // Execute handleTabsCheck on the next animation frame
      requestAnimationFrame(handleTabsCheck)
    },
    [userListRef]
  )

  return (
    <RCDockLayout
      onLayoutChange={handleLayoutChange}
      dockLayoutRef={dockLayoutRef}
      defaultLayout={defaultLayout}
      groups={RcGroup}
    />
  )
}
export default ManageUser

import React from 'react'

import style from './ToggleButton.module.scss'

export interface IToggleButton {
  id?: string
  name: string
  isChecked?: boolean
  checked?: boolean
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  label?: string
  className?: string
  disabled?: boolean
  [key: string]: any
}

const ToggleButton = ({
  id,
  name,
  isChecked,
  checked,
  onChange,
  onBlur,
  label,
  className = '',
  disabled = false
}: // ...props
IToggleButton) => {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      {label && <span className='me-5 form-label'>{label}:</span>}
      <label className={style.switch}>
        <input
          // {...props}
          id={id}
          type='checkbox'
          defaultChecked={isChecked}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          {...(checked !== undefined && { checked: checked })}
        />
        <span className={`${style.slider} ${style.round}`}></span>
      </label>
    </div>
  )
}
export default ToggleButton
